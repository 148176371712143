





































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import VerticalBarChart from '../../../components/VerticalBarChart.vue'
import HorizontalBarChart from '../../../components/HorizontalBarChart.vue'
import b64toBlob from 'b64-to-blob'
import moment from 'moment'
import { Unauthorized } from '@/api/HTTPStatusCodes'

@Component({
  components: {
    VerticalBarChart,
    HorizontalBarChart,
  },
})
export default class StatisticsGroupsSales extends Vue {
  unauthorized = false

  $refs: Vue['$refs'] & {
    form: {
      validate: any
    }
  }

  todayDate = moment().format('YYYY-MM-DD')

  filteringPanel = [0]
  filterLoading = false
  exportToExcelLoading = false

  showBonus = true
  mekonomenGroupId = 2
  includeCustomersWithoutSales = true

  incomeHeaders: { withBonusColumn?: any[]; withoutBonusColumn?: any[] } = {}

  pagination = {
    descending: true,
    page: 1,
    itemsPerPage: -1,
    sortBy: [],
  }

  subGroupsData = []
  customerGroups = []
  allCustomerSubGroups = []
  customerGroupSubGroups = []

  totalOthersAmountRange = null
  totalOthersAmountYear = null
  totalBonus = null

  fromDateMenu = false
  toDateMenu = false

  datePickerType = 'date'

  filteringDatePickerType = this.datePickerType
  filteringFromDate = null
  filteringToDate = null
  filteringCustomerGroup = null
  filteringCustomerSubGroup = null

  productTypeTyreId = 1
  productTypeRimId = 2
  productTypes = [
    {
      id: null,
      name: this.$t('c:common:All'),
    },
    {
      id: this.productTypeTyreId,
      name: this.$t('c:product-type:Tyre'),
    },
    {
      id: this.productTypeRimId,
      name: this.$t('c:product-type:Rim'),
    },
  ]

  chosenCustomer = null
  customerDetailsLoading = false
  customerFilterProductType = 1 // Tyre selected by default
  customerFilterProductSubType = 'all'
  customerFilterProductSubTypeWinter = 'allWinter'
  monthSet = null

  chart: any = {
    chartData: {
      labels: [],
      datasets: [] as any[],
    },
    options: [],
  }

  // i18n is not initialized here, so add rules at created function
  private rules = {
    customerGroup: [],
    dateRules: [],
  }

  get requestData() {
    return {
      customer_group:
        this.filteringCustomerGroup !== null && this.filteringCustomerGroup !== undefined
          ? this.filteringCustomerGroup.id
          : null,
      customer_sub_group: this.filteringCustomerSubGroup,
      from_date: this.filteringFromDate,
      to_date: this.filteringToDate,
      date_picker_type: this.filteringDatePickerType,
      include_customers_without_sales: this.includeCustomersWithoutSales,
    }
  }

  get headers() {
    let result = []

    if (this.showBonus) {
      result = this.incomeHeaders.withBonusColumn
    } else {
      result = this.incomeHeaders.withoutBonusColumn
    }

    return result
  }

  get hasResults() {
    return Object.keys(this.subGroupsData).length > 0
  }

  created() {
    this.rules = {
      customerGroup: [(v) => !!v || this.$t('c:validation:This field is required')],
      dateRules: [
        (v) => !!v || this.$t('c:validation:This field is required'),
        (v) => !v || /^\d\d\d\d-\d\d-\d\d$/.test(v) || this.$t('c:validation:Invalid date (ex: YYYY-MM-DD)'),
      ],
    }

    this.$axios
      .get('/v3/statistics/groups/sales/get_initial_data')
      .then((response) => {
        this.customerGroups = response.data.data.customerGroups
        this.allCustomerSubGroups = response.data.data.customerSubGroups

        if (this.customerGroups.length === 1) {
          this.filteringCustomerGroup = this.customerGroups[0]
        }
      })
      .catch((error) => {
        if (error?.response && error.response.status === Unauthorized) {
          this.unauthorized = true
        }
      })
  }

  fillChartData() {
    const labels = []
    const datasets = []

    let dataset = {}
    const periodDatas = []
    const yearDatas = []

    for (const keyI in this.subGroupsData) {
      labels.push(this.subGroupsData[keyI].subGroupName)
      periodDatas.push(this.subGroupsData[keyI].totalOthersAmountRange)
      yearDatas.push(this.subGroupsData[keyI].totalOthersAmountYear)
    }

    dataset = {
      label: this.$t('c:statistics_groups_sales:Turnover in Period'),
      data: periodDatas,
      backgroundColor: '#43A047',
    }

    datasets.push(dataset)

    this.chart = {
      chartData: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        maintainAspectRatio: false,
      },
    }

    this.$root.$emit('chart_data' + '_event', this.chart)
  }

  setBonusState() {
    if (this.filteringCustomerGroup !== null) {
      // this.showBonus = this.filteringCustomerGroup.id === this.mekonomenGroupId
    } else {
      // this.showBonus = false
    }
    this.showBonus = true
  }

  getSubGroupsData() {
    if (this.$refs.form.validate()) {
      this.filterLoading = true
      this.monthSet = null

      this.$axios
        .post('/v3/statistics/groups/sales/get_sales_data', this.requestData)
        .then((response) => {
          this.incomeHeaders = response.data.data.headers
          this.subGroupsData = response.data.data.subGroups
          this.totalOthersAmountRange = response.data.data.totalOthersAmountRange
          this.totalOthersAmountYear = response.data.data.totalOthersAmountYear
          this.totalBonus = response.data.data.totalBonus
          this.setBonusState()
          this.fillChartData()
        })
        .finally(() => {
          this.filterLoading = false
        })
    }
  }

  exportToExcel() {
    if (this.$refs.form.validate()) {
      this.exportToExcelLoading = true

      this.$axios
        .post('/v3/statistics/groups/sales/get_sales_data/export_to_excel', this.requestData)
        .then((response) => {
          const blob = b64toBlob(
            response.data.data.binary,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          )
          if (!window.navigator.msSaveOrOpenBlob) {
            // BLOB NAVIGATOR
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', this.getExcelFileName())
            document.body.appendChild(link)
            link.click()
          } else {
            // BLOB FOR EXPLORER 11
            window.navigator.msSaveOrOpenBlob(blob, this.getExcelFileName())
          }
        })
        .finally(() => {
          this.exportToExcelLoading = false
        })
    }
  }

  getExcelFileName() {
    let result = 'groups_sales'

    if (this.filteringCustomerGroup !== null) {
      result += '_' + this.filteringCustomerGroup.name.toLowerCase()
    }

    return result + '-' + this.todayDate + '.xlsx'
  }

  private setCustomer(item) {
    this.chosenCustomer = item
    // If we are retuning to overview, re-load data, just in case date changed
    if (item === null) {
      this.getSubGroupsData()
    }
  }

  @Watch('chosenCustomer')
  @Watch('filteringFromDate')
  @Watch('filteringToDate')
  @Watch('customerFilterProductType')
  @Watch('customerFilterProductSubType')
  @Watch('customerFilterProductSubTypeWinter')
  private onCustomerDataChanged() {
    if (this.chosenCustomer !== null) {
      const rimTypes = ['steel', 'aluminium']
      if (this.customerFilterProductType === this.productTypeTyreId) {
        if (rimTypes.indexOf(this.customerFilterProductSubType) !== -1) {
          this.customerFilterProductSubType = 'all'
        }
      } else if (this.customerFilterProductType === this.productTypeRimId) {
        if (rimTypes.indexOf(this.customerFilterProductSubType) === -1) {
          this.customerFilterProductSubType = 'all'
        }
      }

      this.customerDetailsLoading = true
      const data = {
        customerId: this.chosenCustomer.customerId,
        fromDate: this.filteringFromDate,
        toDate: this.filteringToDate,
        productType: this.customerFilterProductType,
        // if all winter, then treat differently
        productSubType:
          this.customerFilterProductType === this.productTypeTyreId ||
          this.customerFilterProductType === this.productTypeRimId
            ? this.customerFilterProductSubType === 'winter'
              ? this.customerFilterProductSubTypeWinter
              : this.customerFilterProductSubType
            : null,
      }
      this.$axios
        .post('/v3/statistics/groups/sales/get_customers_data', data)
        .then((response) => {
          Vue.set(this.chosenCustomer, 'details', response.data.data)
        })
        .catch((error) => {
          if (error?.response && error.response.status === Unauthorized) {
            this.unauthorized = true
          }
        })
        .finally(() => {
          this.customerDetailsLoading = false
        })
    }
  }

  private setMonth(months) {
    this.filteringFromDate =
      moment()
        .subtract(parseInt(months) - 1, 'month')
        .format('YYYY-MM') + '-01'
    this.filteringToDate = moment().format('YYYY-MM-DD')

    this.monthSet = months
  }

  private getPreviousValue(items, field, identification) {
    for (let i = 0; i < items.length; i++) {
      if (items[i].identification === identification) {
        return parseFloat(items[i][field])
      }
    }

    return 0
  }

  // todo: centralize together with getPercentageChange
  private getPercentageChangeNew(oldNumber, newNumber) {
    oldNumber = parseFloat(oldNumber)
    newNumber = parseFloat(newNumber)

    let percentage
    if (oldNumber === 0 && newNumber === 0) {
      percentage = 0
    } else if (oldNumber === 0) {
      percentage = 100
    } else if (newNumber === 0) {
      percentage = -100
    } else {
      const decreaseValue = oldNumber - newNumber
      percentage = -1 * (decreaseValue / oldNumber) * 100
    }

    let color = ''
    let icon = ''
    if (percentage > 0) {
      color = 'green'
      icon = 'fa-arrow-up'
    } else if (percentage < 0) {
      color = 'red'
      icon = 'fa-arrow-down'
    } else {
      color = 'orange'
      icon = 'fa-arrow-right'
    }

    return [{ color: color, icon: icon, percentage: percentage.toFixed(0) }]
  }

  private getPercentageChange(object, item, currentItem = null) {
    let oldNumber, newNumber
    if (currentItem) {
      oldNumber = this.getPreviousValue(object.previous, item, currentItem.identification)
      newNumber = currentItem[item]
    } else {
      oldNumber = object.previous[item]
      newNumber = object.current[item]
    }

    oldNumber = parseFloat(oldNumber)
    newNumber = parseFloat(newNumber)

    let percentage
    if (oldNumber === 0 && newNumber === 0) {
      percentage = 0
    } else if (oldNumber === 0) {
      percentage = 100
    } else if (newNumber === 0) {
      percentage = -100
    } else {
      const decreaseValue = oldNumber - newNumber
      percentage = -1 * (decreaseValue / oldNumber) * 100
    }

    let color = ''
    let icon = ''
    if (percentage > 0) {
      color = 'green'
      icon = 'fa-arrow-up'
    } else if (percentage < 0) {
      color = 'red'
      icon = 'fa-arrow-down'
    } else {
      color = 'orange'
      icon = 'fa-arrow-right'
    }

    return [{ color: color, icon: icon, percentage: percentage.toFixed(0) }]
  }

  private formatPrice(value) {
    const formatter = new Intl.NumberFormat('sv-SE', {
      useGrouping: true,
      maximumFractionDigits: 0,
    })
    return formatter.format(value)
  }

  @Watch('filteringCustomerGroup')
  populateSubGroups() {
    if (this.filteringCustomerGroup !== null) {
      const currentCustomerSubGroups = this.allCustomerSubGroups[this.filteringCustomerGroup.id]
      this.customerGroupSubGroups = currentCustomerSubGroups
      if (currentCustomerSubGroups.length === 1) {
        this.filteringCustomerSubGroup = [currentCustomerSubGroups[0].id]
      }
    }
  }

  @Watch('filteringDatePickerType')
  cleanDates() {
    this.filteringFromDate = null
    this.filteringToDate = null
    this.datePickerType = this.filteringDatePickerType
  }
}
